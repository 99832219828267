@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .disabled {
    @apply pointer-events-none opacity-50;
  }
}

.marginless-editor .public-DraftStyleDefault-block {
  @apply mb-0;
}

.dropdown-item-maintext {
  font-size: 16px;
  color: #2f3941;
  display: block;
}

.dropdown-item-subtext {
  display: block;
  line-height: 16px;
  color: #68737d;
  font-size: 12px;
}

.rdw-image-modal-upload-option-label {
  display: block;
  max-width: 180px;
  word-wrap: break-word;
}
.public-DraftStyleDefault-block {
  margin: 0;
  margin-bottom: 12px;
  white-space: normal;
}
.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR::before {
  left: -30px;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: disc !important;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3.6em;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 5.7em;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 7.8em;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 9.8em;
}
.rdw-link-decorator-icon {
  display: none;
}

.DraftEditor-editorContainer h1 {
  font-weight: bold;
  margin: 0.2em 0;
}

.DraftEditor-editorContainer h2 {
  font-weight: bold;
  margin: 0.2em 0;
}

.DraftEditor-editorContainer h3 {
  font-weight: bold;
  margin: 0.2em 0;
}

.DraftEditor-editorContainer h4 {
  font-weight: bold;
  margin: 0.2em 0;
}

.DraftEditor-editorContainer h5 {
  font-weight: bold;
  margin: 0.2em 0;
}

.response-form .column {
  flex: 1;
  margin: 0 5px;
}
.response-form .column:first-of-type {
  margin-left: 0;
}
.response-form .column:last-of-type {
  margin-right: 0;
}

.household-radio:checked:enabled ~ label::before {
  border-color: #e3e3e3 !important;
  background-color: #edb724 !important;
  background-image: none !important;
}

strong {
  font-weight: bolder;
}

.garden-drawer-transition-enter:not(.garden-drawer-transition-enter-active) {
  transform: translateX(520px) !important;
}

.garden-drawer-transition-exit-active {
  transform: translateX(520px) !important;
}


/**
 * The `.trigger-input-position-fix` class fixes a bug we've identified in the `@zendeskgarden/react-dropdowns` package.
 * When a Zendesk App is located in the sidebar and has a lot of content (content is at least 2x as high as the
 * sidebar iframe), clicking the dropdown trigger will scroll the parent page downwards.
 * This happens because the package positions a hidden input element `fixed` when it should be `absolute`.
 * The input is defined here: https://github.com/zendeskgarden/react-components/blob/main/packages/dropdowns/src/styled/select/StyledInput.ts#L15
 * And it gets focus here: https://github.com/zendeskgarden/react-components/blob/main/packages/dropdowns/src/elements/Trigger/Trigger.tsx#L42
 *
 * We created a PR to fix this issue in the Zendesk package here: https://github.com/zendeskgarden/react-components/pull/1408
 */
.trigger-input-position-fix input[data-garden-id="forms.input"] {
  position: absolute;
}
