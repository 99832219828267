.rdw-image-modal-upload-option-label {
  display: block;
  max-width: 180px;
  word-wrap: break-word;
}

.rdw-editor-toolbar {
  border-color: #d8dcde;
  border-radius: 4px;
}
.rdw-editor-main {
  border-radius: 4px;
  min-height: 300px;
  padding: 10px 12px;
  border: 1px solid #d8dcde;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  border-radius: 3px;
}
.rdw-option-wrapper:hover,
.rdw-dropdown-wrapper:hover,
.rdw-option-wrapper:active,
.rdw-dropdown-wrapper:active {
  border-color: #d8dcde;
  box-shadow: none;
}

.public-DraftStyleDefault-block {
  margin: 0;
  margin-bottom: 12px;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR::before {
  left: -30px;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: disc !important;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3.6em;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 5.7em;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 7.8em;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 9.8em;
}
.rdw-link-decorator-icon {
  display: none;
}

.DraftEditor-editorContainer h1 {
  font-weight: bold;
  margin: 0.2em 0;
  font-size: 24px;
}

.DraftEditor-editorContainer h2 {
  font-weight: bold;
  margin: 0.2em 0;
  font-size: 20px;
}

.DraftEditor-editorContainer h3 {
  font-weight: bold;
  margin: 0.2em 0;
}

.DraftEditor-editorContainer h4 {
  font-weight: bold;
  margin: 0.2em 0;
  font-size: 18px;
}

.DraftEditor-editorContainer h5 {
  font-weight: bold;
  margin: 0.2em 0;
  font-size: 14px;
}

.rdw-dropdown-selectedtext,
.rdw-dropdown-selectedtext:hover {
  color: #000 !important;
}
.rdw-right-aligned-block .public-DraftStyleDefault-block {
  text-align: right !important;
}
.rdw-center-aligned-block .public-DraftStyleDefault-block {
  text-align: center !important;
}
.rdw-justify-aligned-block .public-DraftStyleDefault-block {
  text-align: justify !important;
}
.rdw-left-aligned-block .public-DraftStyleDefault-block {
  text-align: left !important;
}
.rdw-list-item-font-size-8 {
  font-size: 8px;
  line-height: 8px;
}
.rdw-list-item-font-size-9 {
  font-size: 9px;
  line-height: 9px;
}
.rdw-list-item-font-size-10 {
  font-size: 10px;
  line-height: 10px;
}
.rdw-list-item-font-size-11 {
  font-size: 11px;
  line-height: 11px;
}
.rdw-list-item-font-size-12 {
  font-size: 12px;
  line-height: 12px;
}
.rdw-list-item-font-size-14 {
  font-size: 14px;
  line-height: 14px;
}
.rdw-list-item-font-size-16 {
  font-size: 16px;
  line-height: 16px;
}
.rdw-list-item-font-size-18 {
  font-size: 18px;
  line-height: 18px;
}
.rdw-list-item-font-size-24 {
  font-size: 24px;
  line-height: 24px;
}
.rdw-list-item-font-size-30 {
  font-size: 30px;
  line-height: 30px;
}
.rdw-list-item-font-size-36 {
  font-size: 36px;
  line-height: 36px;
}
.rdw-list-item-font-size-48 {
  font-size: 48px;
  line-height: 48px;
}
.rdw-list-item-font-size-60 {
  font-size: 60px;
  line-height: 60px;
}
.rdw-list-item-font-size-72 {
  font-size: 72px;
  line-height: 72px;
}
.rdw-list-item-font-size-96 {
  font-size: 96px;
  line-height: 96px;
}
.rdw-list-item-font-size-24.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -38px;
  width: 38px;
}
.rdw-list-item-font-size-30.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -46px;
  width: 46px;
}

.rdw-list-item-font-size-36.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -56px;
  width: 56px;
}

.rdw-list-item-font-size-48.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -75px;
  width: 75px;
}

.rdw-list-item-font-size-60.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -90px;
  width: 90px;
}

.rdw-list-item-font-size-72.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -115px;
  width: 115px;
}

.rdw-list-item-font-size-96.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -150px;
  width: 150px;
}
