/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

*,:after,:before{
	box-sizing:border-box;
}

:root{
	-moz-tab-size:4;
	-o-tab-size:4;
	   tab-size:4;
}

html{
	line-height:1.15;
	-webkit-text-size-adjust:100%;
}

body{
	margin:0;
	font-family:system-ui,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
}

hr{
	height:0;
	color:inherit;
}

abbr[title]{
	-webkit-text-decoration:underline dotted;
	        text-decoration:underline dotted;
}

b,strong{
	font-weight:bolder;
}

code,kbd,pre,samp{
	font-family:ui-monospace,SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
	font-size:1em;
}

small{
	font-size:80%;
}

sub,sup{
	font-size:75%;
	line-height:0;
	position:relative;
	vertical-align:baseline;
}

sub{
	bottom:-.25em;
}

sup{
	top:-.5em;
}

table{
	text-indent:0;
	border-color:inherit;
}

button,input,optgroup,select,textarea{
	font-size:100%;
	line-height:1.15;
	margin:0;
}

button,select{
	text-transform:none;
}

[type=button],[type=reset],[type=submit],button{
	-webkit-appearance:button;
}

::-moz-focus-inner{
	border-style:none;
	padding:0;
}

:-moz-focusring{
	outline:1px dotted ButtonText;
}

:-moz-ui-invalid{
	box-shadow:none;
}

legend{
	padding:0;
}

progress{
	vertical-align:baseline;
}

::-webkit-inner-spin-button,::-webkit-outer-spin-button{
	height:auto;
}

[type=search]{
	-webkit-appearance:textfield;
	outline-offset:-2px;
}

::-webkit-search-decoration{
	-webkit-appearance:none;
}

::-webkit-file-upload-button{
	-webkit-appearance:button;
	font:inherit;
}

summary{
	display:list-item;
}

html{
  background-color:#fff;
  min-height:100%;
  box-sizing:border-box;
  overflow-y:scroll;
  line-height:20px;
  color:#2f3941;
  font-feature-settings:"kern", "kern";
  -webkit-font-kerning:normal;
          font-kerning:normal;
  font-family:system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Arial,sans-serif;
  font-size:14px;
}

*{ font-weight:inherit; }

*,:after,:before{ box-sizing:inherit; }

a{ color:#1f73b7; }

a,ins,u{ text-decoration:none; }

a:focus,a:hover{
  text-decoration:underline;
  color:#1f73b7;
}

a:focus{ outline:none; }

b{ font-weight:600; }

button{
  cursor:pointer;
  padding:0;
}

button,input,optgroup,select,textarea{
  line-height:inherit;
  font-family:inherit;
}

code{ font-size:.95em; }

code,kbd,pre,samp{ font-family:SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace; }

em{ font-style:inherit; }

fieldset,iframe{ border:0; }

h1,h2,h3,h4,h5,h6{ font-size:inherit; }

blockquote,dd,dl,fieldset,figure,h1,h2,h3,h4,h5,h6,hr,ol,p,pre,ul{
  margin:0;
  padding:0;
}

hr{
  border:none;
  border-top:1px solid;
}

ol,ul{ list-style:none; }

img{ max-width:100%; }

strong{ font-weight:inherit; }

svg{ max-height:100%; }

[tabindex="-1"]:focus{ outline:none !important; }
